<template>
  <!-- 媒体登録ダイアログ -->
  <v-dialog v-model="dialog" scrollable persistent width="1000px">
    <v-card :disabled="loginCheckLoading">
      <v-card-title>
        <span class="text-h5">媒体設定</span>
      </v-card-title>
      <v-card-text class="scrollable-content">
        <input type="hidden" v-model="item.id" />
        <ValidationObserver ref="observer">
          <h3>更新媒体名</h3>
          <div class="mt-2 mb-2"></div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="8">
                <v-text-field
                  prepend-icon="mdi-application-outline"
                  v-model="showSite.name"
                  readonly
                  success
                  name="name"
                  label="媒体名"
                  outlined
                  hide-details="false"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <div :class="snsFlg ? 'd-none' : 'mb-6'">
            <h3>ログイン設定</h3>
            <div class="mt-2 mb-2">
              媒体（サイト）のログインに関する情報を設定してください。
              <span v-show="!hpLink">
                <v-btn
                  text
                  color="primary"
                  class="font-weight-bold pa-1 d-inline"
                  :loading="loginCheckLoading"
                  :disabled="loginCheckLoading"
                  @click="checkLogin"
                  ><span v-html="loginCheckButtonText"> </span></v-btn
              ></span>
            </div>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="ログイン画面のURL"
                    rules="required|url|max:300"
                  >
                    <v-text-field
                      prepend-icon="mdi-cloud"
                      v-model="item.auth_url"
                      v-show="!urlHide"
                      :error-messages="errors"
                      :success="valid"
                      name="auth_url"
                      label="ログイン画面のURL"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="ログインID"
                    rules="required|max:100"
                  >
                    <v-text-field
                      prepend-icon="mdi-account-outline"
                      v-model="item.loginid"
                      :error-messages="errors"
                      :success="valid"
                      name="auth_loginid"
                      label="ログインID"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
                <v-col cols="12" sm="6">
                  <ValidationProvider
                    v-slot="{ errors, valid }"
                    name="パスワード"
                    rules="required|max:100"
                  >
                    <v-text-field
                      prepend-icon="mdi-lock"
                      v-model="item.password"
                      :error-messages="errors"
                      :success="valid"
                      name="auth_password"
                      label="パスワード"
                      outlined
                    ></v-text-field>
                  </ValidationProvider>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div :class="snsFlg ? 'mb-6' : 'd-none'">
            <h3>アカウント設定</h3>
            <div class="mt-2 mb-2">
              連携ボタンをクリックして、投稿先のアカウントにログインし、ヤトイテからのアクセス許可を承認してください。<br />
              <span>
                ※連携に成功するとアカウント名が表示されます。アカウントを変更したい場合は、解除してもう一度連携してください。<br />
              </span>
              <span class="text-red">
                ※管理者用の画面からは連携できません。
              </span>
            </div>
            <v-container>
              <v-row>
                <v-col cols="6" sm="2">
                  <general-button
                    btn_type="info"
                    :btn_loading="linkProcess"
                    :btn_disabled="true"
                    btn_block
                    ><template v-slot:icon
                      ><v-icon left>mdi-link</v-icon></template
                    >連携</general-button
                  >
                </v-col>
                <!-- <v-col cols="6" sm="2" :class="linkProcess ? '' : 'd-none'">
                  <general-button
                    btn_type="warning"
                    btn_block
                    :btn_disabled="
                      !item.sns_link_flg || linkDeleteProcess || snsloading
                    "
                    @click-event="snsCancel"
                    ><template v-slot:icon
                      ><v-icon left>mdi-close</v-icon></template
                    >中止</general-button
                  >
                </v-col> -->
                <v-col cols="6" sm="2">
                  <general-button
                    btn_type="error"
                    :btn_loading="linkDeleteProcess"
                    :btn_disabled="!item.sns_link_flg || linkDeleteProcess"
                    btn_block
                    @click-event="snsDelete"
                    ><template v-slot:icon
                      ><v-icon left>mdi-link-off</v-icon></template
                    >解除</general-button
                  >
                </v-col>
                <v-col cols="12" sm="6" class="d-flex align-center">
                  <div
                    v-if="item.sns_link_flg"
                    class="d-inline-flex align-center"
                  >
                    <v-avatar size="36px">
                      <img alt="Avatar" :src="item.sns_user_icon" />
                    </v-avatar>
                    <div class="ml-2">
                      {{ item.sns_user_name }}<br />@{{ item.loginid }}
                    </div>
                    <v-btn
                      class="ml-4"
                      icon
                      color="info"
                      title="最新の情報に更新"
                      :loading="snsloading"
                      @click="snsUpdateUserProfile"
                      ><v-icon>mdi-reload</v-icon></v-btn
                    >
                  </div>
                  <div v-else>
                    <span>未連携</span>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <div v-show="item.id != 0 && hpLink">
            <h3 id="title_head">ホームページ連携専用項目</h3>
            <div class="mt-2 mb-2">ホームページ連携に関連する項目です。</div>
            <v-container fluid>
              <v-row>
                <v-col cols="6" sm="4">
                  <general-button
                    btn_type="info"
                    btn_block
                    @click-event="getApiKey"
                    :btn_disabled="item.id == 0"
                    ><template v-slot:icon
                      ><v-icon left>mdi-key-plus</v-icon></template
                    >キー生成</general-button
                  >
                </v-col>
                <v-col cols="6" sm="4">
                  <general-button
                    btn_type="error"
                    btn_block
                    @click-event="deleteApiKey"
                    :btn_disabled="item.id == 0"
                    ><template v-slot:icon
                      ><v-icon left>mdi-key-remove</v-icon></template
                    >キー削除</general-button
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    readonly
                    v-model="shop_site_api_key.value"
                    :success="true"
                    label="ホームページ連携用APIキー"
                    :hint="
                      item.id == 0
                        ? `新規登録時は利用できません。`
                        : `キー生成ボタンで連携用のAPIキーが表示されます。`
                    "
                    persistent-hint
                    outlined
                    :disabled="item.id == 0"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </div>
          <h3>対応コンテンツ一覧</h3>
          <div class="mt-2 mb-2">
            下記コンテンツの更新に対応しています。更新しないコンテンツはチェックを外してください。<br />
            <span
              v-if="
                !setting.esutama_site_id.includes(Number(item.site_id)) &&
                !snsFlg
              "
            >
              ※スケジュールにチェックを入れておくと毎日6時30分～7時の間に、登録されているスケジュールで自動更新します。
            </span>
            <span v-else-if="!snsFlg" class="red--text">
              ※スケジュールを自動更新する際は、スケジュールにチェックを入れて保存し、スケジュール管理画面で<span
                class="font-weight-bold"
                >「エステ魂更新設定」</span
              >ボタンをクリックして時刻を設定して下さい。
            </span>
          </div>
          <v-data-table
            :headers="headers"
            :items="siteContents"
            item-key="site_content_id"
            :items-per-page="-1"
            :mobile-breakpoint="0"
            hide-default-footer
            dense
            show-select
            :single-select="false"
            v-model="selected"
            no-data-text="対応コンテンツがありません"
            @click:row="rowClick"
          >
            <!-- eslint-disable-next-line -->
            <template v-slot:item.site_content_name="{ item }">
              <span class="text-align-middle"
                >&nbsp;{{ item.site_content_name }}</span
              >
            </template>
            <!-- eslint-disable-next-line -->
            <template v-slot:item.content_name="{ item }">
              <v-icon> {{ item.content_icon }} </v-icon
              ><span class="text-align-middle"
                >&nbsp;{{ item.content_name }}</span
              >
            </template>
          </v-data-table>
          <h3 class="mt-12">個別設定</h3>
          <div class="mt-2 mb-2">媒体個別の情報があれば入力してください。</div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="メモ"
                  rules="max:1000"
                >
                  <v-text-field
                    prepend-icon="mdi-text-box"
                    v-model="item.memo"
                    :error-messages="errors"
                    :success="valid"
                    label="メモ"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
              <v-col cols="12" sm="6" id="color-selector">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="カラー"
                  rules="required"
                >
                  <v-text-field
                    prepend-icon="mdi-palette"
                    v-model="item.color"
                    hide-details
                    :error-messages="errors"
                    :success="valid"
                    label="カラー選択"
                    readonly
                    outlined
                  >
                    <template v-slot:append>
                      <v-menu
                        v-model="item.menu"
                        top
                        nudge-bottom="105"
                        nudge-left="16"
                        :close-on-content-click="false"
                      >
                        <template v-slot:activator="{ on }">
                          <div :style="swatchStyle(item)" v-on="on"></div>
                        </template>
                        <v-card>
                          <v-card-text class="pa-0">
                            <v-color-picker
                              v-model="item.color"
                              flat
                              show-swatches
                              mode="hexa"
                            />
                          </v-card-text>
                        </v-card>
                      </v-menu>
                    </template>
                  </v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>

          <h3>個別のサーバ設定</h3>
          <div class="mt-2 mb-2">
            共用サーバを使用せず、個別サーバを経由して媒体を更新します。<span
              class="red--text"
              >運営管理画面のみ、編集可能。</span
            >
          </div>
          <v-container>
            <v-row>
              <v-col cols="12" sm="12">
                <ValidationProvider
                  v-slot="{ errors, valid }"
                  name="個別サーバ"
                  rules="ipv4port"
                >
                  <v-text-field
                    prepend-icon="mdi-server"
                    v-model="item.proxy_server"
                    :error-messages="errors"
                    :success="valid"
                    :readonly="false"
                    label="個別サーバ"
                    outlined
                  ></v-text-field>
                </ValidationProvider>
              </v-col>
            </v-row>
          </v-container>
        </ValidationObserver>
      </v-card-text>
      <v-card-actions>
        <v-container>
          <v-row justify="end">
            <v-col cols="6" sm="3">
              <general-button
                btn_color="gray"
                btn_block
                @click-event="cancelDialog"
                ><template v-slot:icon
                  ><v-icon left>mdi-close-circle</v-icon></template
                >キャンセル</general-button
              >
            </v-col>
            <v-col cols="6" sm="3">
              <general-button btn_type="info" btn_block @click-event="onSubmit"
                ><template v-slot:icon
                  ><v-icon left>mdi-content-save</v-icon></template
                >保存</general-button
              >
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { reactive, toRefs, defineComponent } from "@vue/composition-api";
import store from "@/store/index.js";
import { repositoryFactory } from "@/repository/repositoryFactory";
import setting from "@/common/setting.js";
import shopSiteApiKeysRepository from "@/repository/models/shopSiteApiKeysRepository";

export default defineComponent({
  setup(props, ctx) {
    const shopSitesRepository = repositoryFactory.get("shopSites");
    const shopSiteContentsRepository =
      repositoryFactory.get("shopSiteContents");
    const sitesRepository = repositoryFactory.get("sites");
    const synchroRepository = repositoryFactory.get("synchro");
    const updateContentsRepository = repositoryFactory.get("updateContents");
    const OpenRepository = repositoryFactory.get("open");
    const ShopSiteApiKeysRepository = repositoryFactory.get("shopSiteApiKeys");

    const state = reactive({
      showSite: [],
      dialog: false, // ダイアログ表示用
      urlHide: false,
      hpLink: false,
      item: {
        id: 0,
        site_id: 0,
        default_site_id: 0,
        loginid: "",
        password: "",
        auth_url: "",
        memo: "",
        color: "",
        mask: "!#XXXXXXXX",
        menu: false,
        proxy_server: null,
        category: "",
        sns_user_name: "",
        sns_user_icon: "",
        sns_link_flg: false,
      },
      loginCheckLoading: false,
      loginCheckButtonText: "ログイン確認",
      siteContents: [],
      headers: [
        {
          text: "",
          value: "data-table-select",
          width: 30,
        },
        {
          text: "コンテンツ名",
          value: "site_content_name",
          sortable: false,
          class: "td_site_content_name",
        },
        {
          text: "タイプ",
          value: "content_name",
          sortable: false,
          class: "td_content_name",
        },
        { text: "タイトル", value: "title_limit", sortable: false },
        { text: "内容　　", value: "body_limit", sortable: false },
        { text: "最低間隔", value: "min_interval", sortable: false },
      ],
      selected: [],
      shop_site_api_key: {
        value: "",
      },
      // SNS関連の変数
      snsFlg: false,
      linkProcess: false,
      linkDeleteProcess: false,
      snsloading: false,
    });

    // 編集
    const showDialog = async (editItems) => {
      await store.dispatch("loadingIcon/showIcon"); // ローディング表示

      // サイトの情報を取得する
      state.siteContents.splice(0);
      state.selected.splice(0);
      await sitesRepository
        .admin_get_with_content(editItems.site_id)
        .then((response) => {
          if (response.data) {
            state.showSite = response.data;
            if (response.data.content) {
              Object.keys(response.data.content).forEach(function (key) {
                const name_option = response.data.content[key].is_update
                  ? ""
                  : "（取込のみ）";
                const item = {
                  "site_content_id": response.data.content[key].id,
                  "site_content_name":
                    response.data.content[key].name + name_option,
                  "content_icon": response.data.content[key].content_icon,
                  "content_name": response.data.content[key].content_name,
                  "title_limit":
                    response.data.content[key].title_limit == 0
                      ? "-"
                      : response.data.content[key].title_limit + "文字まで",
                  "body_limit":
                    response.data.content[key].body_limit == 0
                      ? "-"
                      : response.data.content[key].body_limit + "文字まで",
                  "min_interval":
                    response.data.content[key].min_interval == 0
                      ? "-"
                      : response.data.content[key].min_interval + "分",
                  "content_enable": true,
                };
                state.siteContents.push(item);
                if (editItems.id == 0) {
                  state.selected.push(item);
                }
              });
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shopSiteDialog.vue/init sitesRepository.list (" + error + ")"
          );
        });

      // 店舗媒体コンテンツの情報を取得する
      if (editItems.id != 0) {
        const params = {
          shop_site_id: editItems.id,
        };
        state.selected.splice(0);
        await shopSiteContentsRepository
          .admin_list_shopsite(params)
          .then((response) => {
            if (response.data) {
              Object.keys(response.data).forEach(function (key) {
                if (response.data[key].is_enable) {
                  const item = state.siteContents.find(function (element) {
                    return (
                      element.site_content_id ==
                      response.data[key].site_content_id
                    );
                  });
                  state.selected.push(item);
                }
              });
            }
          })
          .catch((error) => {
            throw (
              "ERROR:shopSiteDialog.vue/init shopSiteContentsRepository.list (" +
              error +
              ")"
            );
          });
      }

      // SNS関連か判定
      if (setting.sns_site_ids.includes(editItems.site_id)) {
        console.log("SNS関連", editItems);
        state.snsFlg = true;
      } else {
        state.snsFlg = false;
      }

      // ダイアログにアイテムを格納
      state.item.id = editItems.id;
      state.item.shop_id = editItems.shop_id;
      state.item.site_id = editItems.site_id;
      state.item.loginid = editItems.loginid;
      state.item.password = editItems.password;
      state.item.auth_url = editItems.auth_url;
      state.item.memo = editItems.memo;
      state.item.color = editItems.color;
      // 新規かつエステラブの時、PROXYサーバをあらかじめ設定しておく
      if (editItems.id == 0 && editItems.site_id == setting.eslove_site_id) {
        state.item.proxy_server = process.env.VUE_APP_ESLOVE_PROXY;
      } else {
        state.item.proxy_server = editItems.proxy_server;
      }
      state.item.category = editItems.category;
      state.item.sns_user_name = editItems.sns_user_name;
      state.item.sns_user_icon = editItems.sns_user_icon;
      state.item.sns_link_flg = editItems.sns_link_flg;

      // default_auth_urlが設定されている媒体は、URL入力欄を非表示にする
      if (state.showSite.default_auth_url) {
        state.urlHide = true;
      } else {
        state.urlHide = false;
      }
      // ホームページ連携で更新の場合はAPIキーを取得する
      if (state.item.category == setting.site_category_hp_link) {
        state.hpLink = true;
        state.urlHide = false;
        if (editItems.id != 0) {
          state.shop_site_api_key.value = await shopSiteApiKeysRepository
            .admin_get_key_type_hp(editItems.id)
            .then((response) => {
              if (response.data) {
                return response.data.value;
              } else {
                return "";
              }
            })
            .catch((error) => {
              // 取得できない時は空文字を返す
              return "";
            });
        }
      } else {
        state.hpLink = false;
      }

      // ダイアログ開く
      state.dialog = true;
      scrollReset();

      // ローディング非表示
      await store.dispatch("loadingIcon/hideIcon");
    };

    // 保存処理
    const onSubmit = async (preSave = false) => {
      // 入力チェック（SNSの時は、チェックしない）
      let isValid = true;
      if (!state.snsFlg) {
        isValid = await ctx.refs.observer.validate();
      }

      if (!isValid) {
        // エラー処理
      } else {
        if (!state.snsFlg) {
          const logincheck = await checkLogin();
          if (logincheck) {
            if (!window.confirm("保存してよろしいですか？")) {
              return false;
            }
          } else {
            if (
              !window.confirm(
                "媒体にログインできませんでした。保存してよろしいですか？"
              )
            ) {
              return false;
            }
          }
        } else {
          if (!preSave) {
            if (state.item.sns_link_flg) {
              if (!window.confirm("保存してよろしいですか？")) {
                return false;
              }
            } else {
              if (
                !window.confirm("連携していません。保存してよろしいですか？")
              ) {
                return false;
              }
            }
          }
        }
        store.dispatch("loadingIcon/showIcon"); // ローディング表示
        // 新規か更新か
        if (state.item.id == 0) {
          // 新規 データ追加処理(店舗媒体)
          const params = {
            shop_id: state.item.shop_id,
            site_id: state.item.site_id,
            auth_url: state.item.auth_url,
            loginid: state.item.loginid, // snsの時は、アカウントのIDが入れる
            password: state.item.password, // snsの時は、アカウントのトークンが入れる
            config: JSON.stringify({
              "memo": state.item.memo,
              "color": state.item.color,
              "sns_user_name": state.item.sns_user_name,
              "sns_user_icon": state.item.sns_user_icon,
              "sns_link_flg": state.item.sns_link_flg,
            }),
            proxy_server: state.item.proxy_server,
          };
          await shopSitesRepository
            .admin_create(params)
            .then((response) => {
              if (response.data) {
                // データ追加OK
                state.item.id = response.data.id; // idをセット
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:ShopSiteDialog.vue/onSubmit shopSitesRepository.create (" +
                error +
                ")"
              );
            });

          // エステ魂の場合に限り、スケジュールの自動更新設定を実行しておく
          await saveEstamaSchedule();
        } else {
          // 更新
          const params = {
            id: state.item.id,
            shop_id: state.item.shop_id,
            site_id: state.item.site_id,
            loginid: state.item.loginid,
            password: state.item.password,
            auth_url: state.item.auth_url,
            config: JSON.stringify({
              "memo": state.item.memo,
              "color": state.item.color,
              "sns_user_name": state.item.sns_user_name,
              "sns_user_icon": state.item.sns_user_icon,
              "sns_link_flg": state.item.sns_link_flg,
            }),
            proxy_server: state.item.proxy_server,
          };
          // データ更新処理
          await shopSitesRepository
            .admin_update(params)
            .then((response) => {
              if (response.data) {
                // 更新OK
                ctx.refs.observer.reset(); // 入力エラー表示クリア
              }
            })
            .catch((error) => {
              throw (
                "ERROR:ShopSiteDialog.vue/onSubmit shopSitesRepository.update (" +
                error +
                ")"
              );
            });
        }
        // shop_site_contentsテーブル更新
        await saveShopSiteContents().finally(() => {
          // ローディング非表示
          store.dispatch("loadingIcon/hideIcon");

          if (preSave) {
            return true;
          } else {
            cancelDialog();
            ctx.emit("save");
          }
        });
      }
    };

    const saveShopSiteContents = async () => {
      // shop_site_contentsテーブル更新
      const site_content_ids = [];
      for (let i = 0; i < state.selected.length; i++) {
        site_content_ids.push(state.selected[i].site_content_id);
      }
      const params = {
        shop_site_id: state.item.id,
        site_content_id: site_content_ids,
      };
      await shopSiteContentsRepository
        .admin_save_list(params)
        .then((response) => {
          if (response.data) {
            // 更新OK
            if (!response.data.result) {
              alert("店舗媒体コンテンツの保存に失敗しました。");
              return false;
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shopSiteContentsRepository.admin_save_list (" + error + ")"
          );
        });
    };

    const saveEstamaSchedule = async () => {
      // エステ魂の場合に限り、スケジュールの自動更新設定を実行しておく
      if (setting.esutama_site_id.includes(Number(state.item.site_id))) {
        await updateContentsRepository
          .admin_save_esutama_schedule({
            shop_id: state.item.shop_id,
          })
          .then((response) => {
            if (response.data) {
              // データ追加OK
            }
          })
          .catch((error) => {
            throw (
              "ERROR:ShopSiteDialog.vue/onSubmit updateContentsRepository.save_esutama_schedule (" +
              error +
              ")"
            );
          });
      }
    };

    // ダイアログキャンセルボタン
    const cancelDialog = () => {
      ctx.refs.observer.reset(); // 入力エラー表示クリア
      state.dialog = false;
    };

    // ログインチェック
    const checkLogin = async () => {
      // ホームページ連携の場合は、ログインチェックしない
      if (state.hpLink) {
        return true;
      }
      // 入力チェック
      const isValid = await ctx.refs.observer.validate();
      if (!isValid) {
        // エラー処理
        return false;
      } else {
        // ログインチェック中は、ボタン・カードをdisabledに。
        state.loginCheckLoading = true;
        state.loginCheckButtonText = "確認中";
        // ログインチェック処理
        const params = {
          shop_id: state.item.shop_id,
          site_id: state.item.site_id,
          auth_url: state.item.auth_url,
          loginid: state.item.loginid,
          password: state.item.password,
          shop_site_id: state.item.id,
          proxy_server: state.item.proxy_server,
        };
        const result = await synchroRepository
          .admin_login_check(params)
          .then((response) => {
            if (response.data.status) {
              // 確認OK
              state.loginCheckButtonText =
                "<span style='color:blue'>確認できました</span>";
              setTimeout(() => {
                state.loginCheckButtonText = "ログイン確認";
              }, 5000);
              return true;
            } else {
              // 確認NG
              state.loginCheckButtonText =
                "<span style='color:red'>ログインできません</span>";
              setTimeout(() => {
                state.loginCheckButtonText = "ログイン確認";
              }, 5000);
              return false;
            }
          })
          .catch((error) => {
            throw (
              "ERROR:ShopSite.vue/checkLogin synchroRepository.login_check (" +
              error +
              ")"
            );
          });
        // 結果
        state.loginCheckLoading = false;
        return result;
      }
    };

    const swatchStyle = (item) => {
      return {
        backgroundColor: item.color,
        cursor: "pointer",
        height: "30px",
        width: "30px",
        borderRadius: item.menu ? "50%" : "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    };

    const scrollReset = () => {
      setTimeout(() => {
        document.getElementsByClassName("scrollable-content")[0].scrollTop = 0;
        // document.querySelector(".scrollable-content").scroll(0, 0);
        // console.log("sReset");
      }, 200);
    };

    const rowClick = (item, row) => {
      row.select(!row.isSelected);
    };

    const getApiKey = async () => {
      if (
        !window.confirm(
          "APIキーを生成します。よろしいですか？\n※この操作で以前に生成したAPIキーは上書きされます(無効になります)。"
        )
      ) {
        return false;
      }

      await OpenRepository.admin_create_api_key(state.item.id)
        .then((response) => {
          if (response.data) {
            if (response.data.result) {
              state.shop_site_api_key.value = response.data.api_key;
            } else {
              alert(response.data.message);
              state.shop_site_api_key.value = "";
            }
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shop/component/shopDialogComponent.vue/getApiKey OpenRepository.admin_create_api_key (" +
            error +
            ")"
          );
        });
    };

    const deleteApiKey = async () => {
      if (
        !window.confirm(
          "この媒体で生成されていたAPIキーを直ちに削除します。よろしいですか？(保存しなくてもAPIキーは削除されます。)"
        )
      ) {
        return false;
      }
      await ShopSiteApiKeysRepository.admin_delete_hp_shopsiteid(state.item.id)
        .then((response) => {
          if (response.data) {
            // データ削除OK
            state.shop_site_api_key.value = "";
            alert("APIキーを削除しました。");
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shop/component/shopDialogComponent.vue/getApiKey OpenRepository.admin_delete_hp_shopid (" +
            error +
            ")"
          );
        });
    };

    const copyKey = async () => {
      if (state.shop_site_api_key.value) {
        await navigator.clipboard.writeText(state.shop_site_api_key.value);
        alert("APIキーをコピーしました");
      }
    };

    // 運営側の管理画面ではSNS連携操作は対応しない
    // const snsLogin = async () => {
    //   console.log("SNS Login");
    //   state.linkProcess = true;

    //   // shop_siteが未保存の場合、先に保存しておく
    //   if (state.item.id == 0) {
    //     const preSaveResult = await onSubmit(true);
    //     if (!preSaveResult) {
    //       return false;
    //     }
    //   }

    //   // URLを取得する
    //   const params = {
    //     shop_id: store.getters["shops/currentShop"].id,
    //     site_id: state.item.site_id,
    //     auth_url: state.item.auth_url,
    //     loginid: state.item.loginid,
    //     password: state.item.password,
    //     shop_site_id: state.item.id,
    //     proxy_server: state.item.proxy_server,
    //     config: JSON.stringify({
    //       "memo": state.item.memo,
    //       "color": state.item.color,
    //       "sns_user_name": state.item.sns_user_name,
    //       "sns_user_icon": state.item.sns_user_icon,
    //       "sns_link_flg": state.item.sns_link_flg,
    //     }),
    //   };
    //   const res = await synchroRepository
    //     .get_authorization_url(params)
    //     .then((response) => {
    //       console.log("response", response);
    //       return response.data;
    //     })
    //     .catch((error) => {
    //       state.linkProcess = false;
    //       throw (
    //         "ERROR:synchroRepository.sync_get_authorization_url (" + error + ")"
    //       );
    //     });

    //   if (res.status) {
    //     // ブラウザでURLを開く
    //     console.log("URL", res.data.auth_url);
    //     window.location.href = res.data.auth_url;
    //   } else {
    //     alert(res.message);
    //   }

    //   state.linkProcess = false;
    // };

    const snsCancel = () => {
      state.linkProcess = false;
      console.log("SNS Cancel");
    };

    const snsDelete = async () => {
      state.linkDeleteProcess = true;

      if (
        !window.confirm(
          "連携を解除してよろしいですか？\n※保存ボタンに関係なく、ただちに連携は解除されます。"
        )
      ) {
        state.linkDeleteProcess = false;
        return false;
      }
      // 連携を解除する
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        site_id: state.item.site_id,
        auth_url: state.item.auth_url,
        loginid: state.item.loginid,
        password: state.item.password,
        shop_site_id: state.item.id,
      };
      const res = await synchroRepository
        .sns_delete_link(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          state.linkProcess = false;
          throw "ERROR:synchroRepository.sns_delete_link (" + error + ")";
        });
      state.item.sns_user_name = "";
      state.item.sns_user_icon = "";
      state.item.sns_link_flg = false;

      // 媒体情報を更新
      ctx.emit("refresh");

      state.linkDeleteProcess = false;
    };

    const snsUpdateUserProfile = async () => {
      state.snsloading = true;

      // shop_siteが未保存の場合、先に保存しておく
      if (state.item.id == 0) {
        const preSaveResult = await onSubmit(true);
        if (!preSaveResult) {
          return false;
        }
      }

      // ユーザ情報を更新する
      const params = {
        shop_id: store.getters["shops/currentShop"].id,
        site_id: state.item.site_id,
        auth_url: state.item.auth_url,
        loginid: state.item.loginid,
        password: state.item.password,
        shop_site_id: state.item.id,
      };
      const res = await synchroRepository
        .sns_update_user_profile(params)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          state.snsloading = false;
          alert(error.message);
          throw (
            "ERROR:synchroRepository.sns_update_user_profile (" + error + ")"
          );
        });

      if (res.status) {
        state.item.loginid = res.data.user.loginid;
        state.item.sns_user_name = res.data.user.sns_user_name;
        state.item.sns_user_icon = res.data.user.sns_user_icon;
        state.item.sns_link_flg = res.data.user.sns_link_flg;
        // 媒体情報を更新
        ctx.emit("refresh");
      } else {
        alert(res.message);
      }

      state.snsloading = false;
    };

    // 返却オブジェクト定義
    return {
      setting,
      ...toRefs(state),
      onSubmit,
      cancelDialog,
      showDialog,
      checkLogin,
      swatchStyle,
      scrollReset,
      rowClick,
      copyKey,
      getApiKey,
      deleteApiKey,
      // snsLogin, 非対応とする
      snsDelete,
      snsCancel,
      snsUpdateUserProfile,
    };
  },
});
</script>

<style scoped>
::v-deep #color-selector .v-input__append-inner {
  margin-top: 13px;
}
.edit_area {
  cursor: pointer;
}
::v-deep .td_content_name {
  width: 160px;
}
::v-deep .td_site_content_name {
  min-width: 160px;
}
</style>
